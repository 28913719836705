<template>
  <!-- <v-menu
    offset-x
    offset-y
    :close-on-content-click="false"
    min-width="370"
    style="z-index:300"
  >
    <template #activator="{ on }">
      <v-btn
        small
        v-on="on"
      >
        <b>Live</b>Factor
      </v-btn>
    </template> -->
  <v-list
    :color="color"
    dense
  >
    <template v-if="value.patient.gender != 'male'">
      <v-list-item>
        <v-switch
          v-model="value.pregnant"
          :label="$t('live_factor.patient_pregnant')"
          prepend-icon="fal fa-fw fa-person-pregnant"
          color="pink darken-1"
          dense
          inset
        />
      </v-list-item>
      <v-divider />
    </template>
    <v-list-item class="pt-2">
      <v-list-item-icon class="me-1">
        <v-icon :size="18">
          fal fa-fw fa-allergies
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-subtitle class="pt-1 mb-n2">
          {{ $t('live_factor.allergies') }}
        </v-list-item-subtitle>
        <v-autocomplete
          v-model="value.allergies"
          :items="allergies"
          xlabel="$t('live_factor.allergies')"
          class="mt-4 mb-n4"
          item-text="name"
          cache-items
          return-object
          small-chips
          multiple
          outlined
          persistent-placeholder
        >
          <template #selection="{ item }">
            <v-chip
              :key="item.name"
              small
              class="px-2"
              label
            >
              {{ item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item class="pt-2">
      <v-list-item-icon class="me-1">
        <v-icon :size="18">
          fal fa-fw fa-shield-virus
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-subtitle class="pt-1 mb-n2">
          {{ $t('live_factor.resistance_genes') }}
        </v-list-item-subtitle>
        <v-autocomplete
          v-model="value.resistance"
          :items="resistances"
          class="mt-4 mb-n4"
          return-object
          :item-text="(item) => item.name[$i18n.locale] || item.name.en"
          small-chips
          multiple
          outlined
          persistent-placeholder
        >
          <template #selection="{ item }">
            <v-chip
              :key="item.short_name"
              small
              class="px-2"
              label
            >
              {{ (item.name[$i18n.locale] || item.name.en) }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-divider />
    <v-list-item>
      <v-autocomplete
        v-model="value.drug_resistance"
        :label="$t('live_factor.drug_resistance')"
        prepend-icon="fak fa-shield-pill"
        class="mt-4 mb-n2"
        :items="value.organisms.flatMap(x => x.effective_drugs).sort((a,b) => b.name > a.name ? -1 : 1).map(x => x.name)"
        small-chips
        multiple
      />
    </v-list-item> -->
    <v-divider />
    <!-- <v-list-item>
      <drug-finder
        v-model="current_prescriptions"
        :label="$t('live_factor.other_medications')"
        prepend-icon="fal fa-clipboard-prescription"
        class="mt-4 mb-n2"
        multiple
      />
    </v-list-item>
    <v-divider /> -->
    <v-list-item class="pt-1">
      <v-list-item-icon class="me-1">
        <v-icon :size="18">
          fal fa-fw fa-kidneys
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-subtitle class="pt-1 mb-n2">
          {{ $t('live_factor.renal_function') }}
        </v-list-item-subtitle>
        <v-text-field
          v-model.number="renalFunction"
          class="mt-4 mb-n2"
          clearable
          persistent-placeholder
          outlined
        >
          <template #append>
            <v-btn-toggle
              v-model="value.patient.renal_calc_method"
              class="mt-n1 pt-0 ms-0"
              small
            >
              <v-btn
                value="crcl"
                class="px-1"
                small
              >
                CrCl
              </v-btn>
              <v-btn
                value="gfr"
                class="px-0"
                small
              >
                GFR
              </v-btn>
            </v-btn-toggle>
          </template>
          <template #append-outer>
            <v-dialog
              max-width="300"
              top
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  tile
                  class="px-0"
                  v-on="on"
                >
                  <v-icon small>
                    fal fa-calculator
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  Renal Function Calculator
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="value.patient.renal_calc_method"
                        label="Method"
                        dense
                        :items="[
                          { text: 'Creatinine Clearance (CrCl)', value: 'crcl' },
                          { text: 'Glomerular filtration rate (GFR)', value: 'gfr' },
                        ]"
                      />
                    </v-col>
                    <!-- <v-col cols="12">
                    <v-text-field
                      label="Age"
                      :value="patientAge"
                      readonly
                      dense
                    >
                      <template #append>
                        years
                      </template>
                    </v-text-field>
                  </v-col> -->
                    <v-col cols="5">
                      <v-select
                        label="Gender"
                        :value="value.patient.gender"
                        :items="[
                          { text: $t('male'), value: 'male' },
                          { text: $t('female'), value: 'female' },
                          { text: $t('unknown'), value: 'unknown' }
                        ]"
                        readonly
                        dense
                      />
                    </v-col>
                    <v-col
                      v-if="value.patient.renal_calc_method === 'crcl'"
                      cols="7"
                    >
                      <v-text-field
                        v-model.number="value.patient.weight"
                        label="Weight"
                        dense
                      >
                        <template #append>
                          <v-btn-toggle
                            v-model="weight_unit"
                            dense
                            row
                          >
                            <v-btn
                              x-small
                              value="kg"
                              class="px-1"
                            >
                              kg
                            </v-btn>
                            <v-btn
                              x-small
                              value="lbs"
                              class="px-1"
                            >
                              lbs
                            </v-btn>
                          </v-btn-toggle>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="7">
                      <v-select
                        v-if="value.patient.renal_calc_method === 'gfr'"
                        v-model="value.patient.race"
                        label="Race"
                        :items="[
                          { text: 'White', value: 'white' },
                          { text: 'Black', value: 'black' },
                          { text: 'Asian', value: 'asian' },
                          { text: 'Hispanic', value: 'hispanic' },
                          { text: 'Other', value: 'other' },
                        ]"
                        dense
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model.number="value.patient.serum_creatinine"
                        label="Serum creatinine"
                        dense
                      >
                        <template #append>
                          ml/dL
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-text-field
                    v-if="value.patient.renal_calc_method === 'crcl'"
                    label="CrCl (mL/min)"
                    :value="CrCl"
                    readonly
                    outlined
                  />
                  <v-text-field
                    v-if="value.patient.renal_calc_method === 'gfr'"
                    label="GFR (mL/min/1.73m^2)"
                    :value="GFR"
                    readonly
                    outlined
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </v-text-field>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-divider />
    <v-list-item>
      <v-text-field
        v-model="value.crcl"
        label="What is the patient's GFR level?"
        prepend-icon="fak fa-liver"
        class="mt-4 mb-n2"
      >
        <template #append-outer>
          <v-btn
            icon
            small
          >
            <v-icon>fal fa-calculator</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-list-item> -->
  </v-list>
  <!-- </v-menu> -->
</template>
<script>
  export default {
    components: {
      // DrugFinder: () => import('@/components/drug/DrugFinder.vue'),
    },
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
      selectedDrug: {
        type: Object,
        default: () => ({}),
      },
      patientAge: {
        type: Number,
        default: 0,
      },
      color: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
      loading: true,
      allergies: this.value.allergies ?? [],
      resistances: [],
      current_prescriptions: [],
      weight_unit: 'kg',
      }
    },
    computed: {
      CrCl () {
        if (!this.value.patient.serum_creatinine && !this.value.patient.weight) {
          return null
        }
        let crcl = ((140 - this.patientAge) * this.patientWeight) / (this.value.patient.serum_creatinine * 72)
        if (this.value.patient.gender === 'female') {
          crcl *= 0.85
        }
        return crcl.toFixed(2)
      },
      GFR () {
        let a = this.value.patient.gender == 'male' ? -0.411 : -0.329
        let k = this.value.patient.gender == 'male' ? 0.9 : 0.7
        let Scr = this.value.patient.serum_creatinine
        let gfr = 141 * Math.pow(Math.min(Scr/k, 1), a) * Math.pow(Math.max(Scr/k, 1), -1.209) * Math.pow(0.993, this.patientAge)
        if (this.value.patient.gender === 'female') {
          gfr *= 1.018
        }
        if (this.value.patient.race === 'black') {
          gfr *= 1.159
        }
        return gfr.toFixed(2)
      },
      patientWeight () {
        if (this.weight_unit === 'kg') {
          return this.value.patient.weight
        } else {
          return this.value.patient.weight * 0.453592
        }
      },
      renalFunction: {
        get () {
          return this.value.patient.renal_calc_method == 'gfr' ? this.value.patient.gfr : this.value.patient.crcl
        },
        set (value) {
          this.$set(this.value.patient, this.value.patient.renal_calc_method ?? 'crcl', value)
        },
      },
    },
    watch: {
      value () {
        this.$emit('update:value', this.value)
      },
      CrCl (val) {
        this.$set(this.value.patient, 'crcl', val)
      },
      GFR (val) {
        this.$set(this.value.patient, 'gfr', val)
      },
      selectedDrug () {
        this.fetchDrugInteractions()
      },
      current_prescriptions () {
        this.fetchDrugInteractions()
      },
    },
    created () {
      this.axios.get('report/resistance')
        .then((response) => {
          this.resistances = response.data.sort((a,b) => a.name.en > b.name.en ? 1 : -1)
        })

      this.axios.get('report/allergens?relevant=true')
        .then((response) => {
          this.allergies = response.data.map((x) => {
            let y = {}
            y.name = x.name
            y.group = x
            return y
          })
        })
    },
    mounted () {
    },
    methods: {
      fetchDrugInteractions () {
        if (this.current_prescriptions.length) {
          fetch(`https://rxnav.nlm.nih.gov/REST/interaction/list.json?rxcuis=${this.selectedDrug.rxcui}+${this.current_prescriptions.join('+')}`)
            .then(response => response.json())
            .then(data => {
              let live_drug_interactions = data.fullInteractionTypeGroup[0]?.fullInteractionType.filter(x => x.minConcept.find(f => f.rxcui == this.selectedDrug.rxcui))
              this.$set(this.selectedDrug, 'live_drug_interactions', live_drug_interactions.flatMap(x => x.interactionPair))
            })
        } else {
           this.$set(this.selectedDrug, 'live_drug_interactions', null)
        }
      },
    },
  }
</script>
